import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

import Button from '../components/button'

const NotFoundPage = ({ data }) => (
  <Outer>
    {console.log(data)}
    <Inner className="container">
      <h1>404</h1>
      <h2>The page you were looking for doesn’t exist.</h2>
      <Button type="link" to="/" text="Take me home" />
    </Inner>
    <Background fluid={data.wordpressAcfOptions.global_options.notFoundBackground.localFile.childImageSharp.fluid} loading="eager" fadeIn={false} style={{ position: 'absolute' }} />
  </Outer>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          global_options {
            notFoundBackground {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <NotFoundPage data={data} {...props} />}
  />
)

const Outer = styled.section``

const Inner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  z-index: 5;

  h1 {
    font-size: 10rem;
    line-height: 1.2;
    color: #fff;
    margin: 0;
  }

  h2 {
    font-size: 2rem;
    line-height: 1.3;
    font-weight: 100;
    color: #fff;
    margin: 1rem 0 3rem;
  }

  a {
    display: inline-block;
    margin: 0 auto;
  }
`

const Background = styled(Img)`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`
